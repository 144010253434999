<template>
  <taxes-form
    v-if="tax.id"
    ref="taxes-form"
  />
  <component-not-found
    v-else-if="error"
    title="Taxes list"
    :button-link="{name: 'settings-taxes-list'}"
  />
  <skeleton v-else />
</template>

<script>
import taxesForm from '@/views/settings/general/taxes/components/TaxesForm.vue'
import config from '@/views/settings/fulfillment/warehouses/warehouseConfig'
import Skeleton from '../components/Skeleton.vue'

export default {
  name: 'TaxesUpdate',
  components: { taxesForm, Skeleton },
  data() {
    return {
      error: false,
    }
  },
  computed: {
    tax() {
      return this.$store.state[this.MODULE_NAME].taxesForm
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['taxes-form']) this.$refs['taxes-form'].showConfirmationModal(next)
  },
  mounted() {
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(res => {
      const { data } = res.data
      console.log(data)
      const tax = this.mappingFields(Object.keys(this.fields), data, {
        id: data.id,
        is_active: data.is_active,
        label: data.label,
        value: data.value,
        is_default: data.is_default,
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, tax)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_TAX_FORM_CLONE`, tax)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    })
  },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
  },
  setup() {
    const MODULE_NAME = 'settings-taxes'
    const MODULE_NAME_CLONE = 'cloneData'
    const { fields } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>
